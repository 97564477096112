import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Eyebrow, Heading } from "components/ui/Text";
import { InputField, FormSection, SubmitButton } from "components/form";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import Button from "components/ui/Button";
import { Eligibility } from "types/signup";
import * as Styled from "./styled";
import { getContent } from "./utils";

interface Props {
  eligibility: { bank: Eligibility; payroll: Eligibility };
  resetForm: () => void;
  email: string;
  submissionCount: number;
}

const Success: React.FC<Props> = ({
  eligibility,
  resetForm,
  email,
  submissionCount,
}) => {
  const emailRef = useRef(email);
  const [showEmailForm, setShowEmailForm] = useState(false);

  const { watch } = useFormContext();
  const newEmail = watch("user.email");

  useEffect(() => {
    emailRef.current = email;
    setShowEmailForm(false);
  }, [submissionCount]);

  if (showEmailForm) {
    return (
      <FormSection>
        <InputField
          name="user.email"
          label="Email"
          required
          placeholder="val.clark@example.com"
          autoComplete="email"
          type="email"
        />

        <MobileFixedFooter>
          {emailRef.current === newEmail ? (
            <Button onClick={() => setShowEmailForm(false)} fullWidth>
              Update
            </Button>
          ) : (
            <SubmitButton fullWidth>Update</SubmitButton>
          )}
        </MobileFixedFooter>
      </FormSection>
    );
  }

  const content = getContent({
    eligibility,
    email,
    resetForm,
    updateEmail: () => setShowEmailForm(true),
  });

  return (
    <Styled.Tile>
      <Eyebrow>{content.eyebrow}</Eyebrow>

      <Heading.H2 tag="h1">{content.heading}</Heading.H2>

      {content.body}
    </Styled.Tile>
  );
};

export default Success;
