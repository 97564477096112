import React, { useEffect, useRef, useState } from "react";
import { Heading } from "components/ui/Text";
import Button, { ButtonLevel } from "components/ui/Button";
import PlaidModal, {
  ModalApi as PlaidModalApi,
} from "components/shared/PlaidModal";
import IconList, { IconListItem } from "components/ui/IconList";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import { getOnboardingStatus } from "store/api/apiSlice";
import { OnboardingTaskName } from "types/onboarding";
import * as Styled from "./styled";
import Intro from "./Intro";

const LIST_ITEMS: IconListItem[] = [
  {
    icon: "arrowProgress",
    title: "Why connect my checking account?",
    body: "Reset factors in your checking account balance and upcoming bills to create a dynamic spending limit tailored to you.",
  },
  {
    icon: "ballotCheck",
    title: "What if I have multiple accounts?",
    body: (
      <>
        Please choose your{" "}
        <em>
          <strong>primary</strong>
        </em>{" "}
        checking account, where the majority of your direct deposit goes.
      </>
    ),
  },
  {
    icon: "shield",
    title: "Is it safe?",
    body: "Reset uses Plaid to securely connect your checking account and keep your data private.",
  },
];

const Bank = () => {
  const modalRef = useRef<PlaidModalApi>(null);
  const [checkOnboardingStatus, setCheckOnboardingStatus] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const { data: onboardingStatus } = getOnboardingStatus({
    skip: !checkOnboardingStatus,
    pollingInterval: 2000,
  });

  const openModal = () => {
    if (modalRef && modalRef.current) {
      modalRef.current.open();
    }
  };

  useEffect(() => {
    if (
      onboardingStatus &&
      onboardingStatus.currentTask !== OnboardingTaskName.bankLinking
    ) {
      window.location.reload();
    }
  }, [onboardingStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showIntro]);

  if (showIntro) return <Intro hideIntro={() => setShowIntro(false)} />;

  return (
    <Styled.FormStep>
      <Styled.Header>
        <Heading.H2 tag="h1">Let's connect your checking account.</Heading.H2>
      </Styled.Header>

      <IconList items={LIST_ITEMS} isLarge />

      <MobileFixedFooter>
        <Button
          isLoading={checkOnboardingStatus}
          onClick={openModal}
          level={ButtonLevel.cta}
        >
          Connect My Checking Account
        </Button>
      </MobileFixedFooter>

      <PlaidModal
        ref={modalRef}
        onSubmit={() => {
          setCheckOnboardingStatus(true);
        }}
      />
    </Styled.FormStep>
  );
};

export default Bank;
