import React from "react";
import { formatCurrency } from "utils/string";
import * as Styled from "../styled";
import StatusTag from "../../../components/StatusTag";

interface Props {
  value: boolean | string | number;
}

const CriterionValue: React.FC<Props> = ({ value }) => {
  if (typeof value === "boolean") {
    return (
      <Styled.TagWrapper>
        <StatusTag status={value.toString()} />
      </Styled.TagWrapper>
    );
  }

  if (typeof value === "number") {
    return formatCurrency(value, true);
  }

  return value;
};

export default CriterionValue;
