import React from "react";
import App from "App";
import Page from "components/cms/Page";
import { mapEntryToPage } from "utils/contentful";
import { transformKeysDeep } from "utils/object";

const CmsPage = (props) => {
  const pageData = transformKeysDeep(props, (key) => key.replace(/^__/, ""));
  const content = mapEntryToPage(pageData);

  return (
    <App>
      <Page content={content} />
    </App>
  );
};

export default CmsPage;
