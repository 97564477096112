import camelcaseKeys from "camelcase-keys";
import decamelizeKeys from "decamelize-keys";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_PATH, getHeaders } from "utils/api/common";
import {
  ApiPersonalInfosRequest,
  ApiQualifierRequest,
  ApiUserProfile,
  ApiUserRequest,
} from "utils/api/user";
import { ApiInternalMessageRequest } from "utils/api/admins";
import { ApiCardTransaction } from "utils/api/cardTransactions";
import {
  ApiNotification,
  notificationTransformer,
} from "utils/api/notifications";
import { ApiAcceptancesRequest } from "utils/api/acceptances";
import { ApiExpense } from "utils/api/expenses";
import { ApiStatement, ApiStatementMini } from "utils/api/statements";
import {
  ApiContentfulPageResponse,
  ApiContentfulPostResponse,
  ApiContentfulToggleBlockResponse,
} from "utils/api/contentful";
import { ApiPaystub, ApiEarnings } from "utils/api/income";
import { ApiDashboardOverview, ApiDashboardPayday } from "utils/api/dashboard";
import { ApiDisclosures, ApiDisclosureType } from "utils/api/disclosures";
import { ApiBankAccount } from "utils/api/bank_accounts";
import { OnboardingTask, OnboardingTaskName } from "types/onboarding";

type ResponseData = any[] | Record<string, any>;

const apiFetchBaseQuery =
  (baseQueryOptions) => async (args, api, extraOptions) => {
    try {
      const response = await fetchBaseQuery(baseQueryOptions)(
        args,
        api,
        extraOptions
      );

      if (response.error?.status === 401) {
        window.location.pathname = "/users/sign_in";
      } else {
        const { data } = response;
        response.data = camelcaseKeys(data as ResponseData, { deep: true });
      }

      return response;
    } catch (error) {
      return { error };
    }
  };

const TAG_TYPES = [
  "ActiveRecurringOutflow",
  "Notification",
  "CardTransaction",
  "UserProfile",
  "IdentityVerification",
  "Statements",
  "Statement",
  "Dashboard",
  "Payday",
  "Paystubs",
  "Earnings",
  "Page",
  "Post",
  "ToggleBlock",
  // mock-only
  "MockUsers",
];

const MOCK_TAGS_TO_INVALIDATE = [
  "MockUsers",
  "UserProfile",
  "Dashboard",
  "CardTransaction",
  "ActiveRecurringOutflow",
  "Earnings",
  "Payday",
];

// TODO: break this up into multiple files, e.g. 1 file per resource and use injectEndpoints
const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: apiFetchBaseQuery({
    baseUrl: API_PATH,
  }),
  tagTypes: TAG_TYPES,
  endpoints: (builder) => ({
    postUser: builder.mutation<{}, ApiUserRequest>({
      query: (body) => ({
        url: "/users",
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body, { deep: true }),
      }),
    }),
    postUserSmsOptIn: builder.mutation<{}, void>({
      query: () => ({
        url: "/users/sms_opt_in",
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["UserProfile"],
    }),
    postUserSmsOptOut: builder.mutation<{}, void>({
      query: () => ({
        url: "/users/sms_opt_out",
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["UserProfile"],
    }),
    postQualifier: builder.mutation<{}, ApiQualifierRequest>({
      query: (body) => ({
        url: "/qualifier/waitlist",
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body, { deep: true }),
      }),
    }),
    getOnboardingStatus: builder.query<
      { complete: boolean; currentTask: OnboardingTaskName },
      void
    >({
      query: () => "/onboarding/status",
    }),
    postLogPinwheelEvent: builder.mutation<
      {},
      { eventName: string; eventData: string }
    >({
      query: (body) => ({
        url: "/pinwheel/log_event",
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body, { deep: true }),
      }),
    }),
    getCardApplicationStatus: builder.query<{ complete: boolean }, void>({
      query: () => "/card_application/status",
    }),
    getCardApplicationTasks: builder.query<OnboardingTask[], void>({
      query: () => "/card_application/tasks",
    }),
    postCardApplicationConfirmRepaymentAccount: builder.mutation<{}, void>({
      query: () => ({
        url: "/card_application/confirm_repayment_account",
        method: "POST",
        headers: getHeaders(),
      }),
    }),
    getActiveRecurringOutflows: builder.query<ApiExpense[], void>({
      query: () => "/recurring_transactions/active_recurring_outflows",
      providesTags: ["ActiveRecurringOutflow"],
    }),
    getDisclosures: builder.query<ApiDisclosures, ApiDisclosureType[]>({
      query: (disclosure_types) =>
        `/disclosures${disclosure_types?.length ? `?disclosure_types=${disclosure_types.join(",")}` : ""}`,
      keepUnusedDataFor: 0,
    }),
    getNotifications: builder.query<ApiNotification[], void>({
      query: () => "/notifications",
      transformResponse: notificationTransformer,
      providesTags: ["Notification"],
    }),
    postNotificationMarkAsRead: builder.mutation<{}, string>({
      query: (id) => ({
        url: `/notifications/${id}/mark_as_read`,
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["UserProfile"],
    }),
    getCardTransactions: builder.query<ApiCardTransaction[], void>({
      query: () => "/card_accounts/current/card_transactions",
      providesTags: ["CardTransaction"],
    }),
    // Singular because it fetches the current user's profile
    getUserProfile: builder.query<ApiUserProfile, void>({
      query: () => "/users/profile",
      providesTags: ["UserProfile"],
    }),
    getDashboardOverview: builder.query<ApiDashboardOverview, void>({
      query: () => "/dashboard/overview",
      providesTags: ["Dashboard"],
    }),
    getDashboardPayday: builder.query<ApiDashboardPayday, void>({
      query: () => "/dashboard/payday",
      providesTags: ["Payday"],
    }),
    getPinwheelStatus: builder.query<{ monitoringOk: boolean }, void>({
      query: () => "/pinwheel/status",
    }),
    getPaystubs: builder.query<{ data: ApiPaystub[] }, void>({
      query: () => "/income/paystubs",
      providesTags: ["Paystubs"],
    }),
    getEarnings: builder.query<ApiEarnings, void>({
      query: () => "/income/earnings",
      providesTags: ["Earnings"],
    }),
    postPersonalInfos: builder.mutation<{}, ApiPersonalInfosRequest>({
      query: (body) => ({
        url: "/personal_infos",
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body, { deep: true }),
      }),
      invalidatesTags: ["UserProfile"],
    }),
    postIdentityVerificationSubmitVerification: builder.mutation<{}, string>({
      query: (document_id) => ({
        url: "/identity_verifications/submit_verification",
        method: "POST",
        headers: getHeaders(),
        body: { document_id },
      }),
    }),
    postAcceptances: builder.mutation<{}, ApiAcceptancesRequest>({
      query: (body) => ({
        url: "/acceptances/bulk",
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body, { deep: true }),
      }),
    }),
    getIdentityVerificationStatus: builder.query({
      query: () => ({
        url: "/identity_verifications/current_status",
        providesTags: ["IdentityVerification"],
      }),
    }),
    getBankAccountsLinked: builder.query<ApiBankAccount, void>({
      query: () => ({
        url: "/bank_accounts/linked",
        providesTags: ["BankAccountsLinked"],
      }),
    }),
    getBankAccountVerificationStatus: builder.query({
      query: () => ({
        url: "/bank_account_verifications/current_status",
        providesTags: ["BankAccountVerification"],
      }),
    }),
    postInternalMessage: builder.mutation<{}, ApiInternalMessageRequest>({
      query: (body) => ({
        url: "/admins/internal_message",
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body, { deep: true }),
      }),
    }),
    getStatements: builder.query<ApiStatementMini[], void>({
      query: () => "/statements",
      providesTags: ["Statements"],
    }),
    getStatement: builder.query<{ statement: ApiStatement }, string>({
      query: (id) => `/statements/${id}`,
      providesTags: ["Statement"],
    }),
    postSendCode: builder.mutation<{ success: boolean }, string>({
      query: (id) => ({
        url: "/multifactor_authentication_configurations/send_code",
        method: "POST",
        headers: getHeaders(),
        body: { user_id: id },
      }),
    }),
    postVerifyCode: builder.mutation<
      { success: boolean },
      { otpAttempt: string; type: "enable_mfa" | "disable_mfa" }
    >({
      query: (body) => ({
        url: "/multifactor_authentication_configurations/verify_code",
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body, { deep: true }),
      }),
    }),
    getContentfulPage: builder.query<
      ApiContentfulPageResponse,
      { slug: string; section?: string; preview?: boolean }
    >({
      query: (params) => {
        const queryString = new URLSearchParams(
          Object.entries(params).reduce((obj, [key, value]) => {
            if (value === undefined || value === null) return obj;
            return {
              ...obj,
              [key]: value.toString(),
            };
          }, {})
        ).toString();
        return {
          url: `/contentful/page?${queryString}`,
        };
      },
      providesTags: ["Page"],
    }),
    getContentfulPost: builder.query<
      ApiContentfulPostResponse,
      { slug: string; preview?: boolean }
    >({
      query: (params) => {
        const queryString = new URLSearchParams(
          Object.entries(params).reduce((obj, [key, value]) => {
            if (value === undefined || value === null) return obj;
            return {
              ...obj,
              [key]: value.toString(),
            };
          }, {})
        ).toString();
        return {
          url: `/contentful/post?${queryString}`,
        };
      },
      providesTags: ["Post"],
    }),
    getContentfulToggleBlock: builder.query<
      ApiContentfulToggleBlockResponse,
      { id: string }
    >({
      query: (params) => {
        const queryString = new URLSearchParams(
          Object.entries(params).reduce((obj, [key, value]) => {
            if (value === undefined || value === null) return obj;
            return {
              ...obj,
              [key]: value.toString(),
            };
          }, {})
        ).toString();
        return {
          url: `/contentful/toggle_block?${queryString}`,
        };
      },
      providesTags: ["ToggleBlock"],
    }),
    postSimulateTransaction: builder.mutation<{}, void>({
      query: () => ({
        url: "/simulate/transaction",
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["CardTransaction", "Dashboard"],
    }),
    postSimulateEarnings: builder.mutation<{}, void>({
      query: () => ({
        url: "/simulate/earnings",
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["Dashboard", "Earnings"],
    }),
    postSimulateDirectDeposit: builder.mutation<{}, void>({
      query: () => ({
        url: "/simulate/direct_deposit",
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["Dashboard"],
    }),
    postAdminUserStopImpersonating: builder.mutation<void, void>({
      query: () => ({
        url: "/admin/users/stop_impersonating",
        method: "POST",
        headers: getHeaders(),
      }),
      invalidatesTags: ["Dashboard", "UserProfile", "CardTransaction"],
    }),
    postFinancialHealthSurvey: builder.mutation<
      {},
      { user: { email: string; name: string }; subscores: number[] }
    >({
      query: (body) => ({
        url: "/financial_health/survey",
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body, { deep: true }),
      }),
    }),
    postFinancialHealthBudget: builder.mutation<
      {},
      { user: { email: string; name: string }; data: any }
    >({
      query: (body) => ({
        url: "/financial_health/budget",
        method: "POST",
        headers: getHeaders(),
        body: decamelizeKeys(body, { deep: true }),
      }),
    }),

    // mock-only
    getMockUsers: builder.query<ApiUserProfile[], void>({
      query: () => "/mock/users",
      providesTags: ["MockUsers"],
    }),
    postMockInit: builder.mutation<void, void>({
      query: () => ({ url: "/mock/init", method: "POST" }),
      invalidatesTags: MOCK_TAGS_TO_INVALIDATE,
    }),
    postMockReset: builder.mutation<void, void>({
      query: () => ({ url: "/mock/reset", method: "POST" }),
      invalidatesTags: MOCK_TAGS_TO_INVALIDATE,
    }),
    postMockCreateUser: builder.mutation<ApiUserProfile, any>({
      query: (body) => ({ url: "/mock/users/create", method: "POST", body }),
      invalidatesTags: MOCK_TAGS_TO_INVALIDATE,
    }),
    postMockSetUser: builder.mutation<ApiUserProfile, { id: string }>({
      query: (body) => ({
        url: "/mock/users/set_current",
        method: "POST",
        body,
      }),
      invalidatesTags: MOCK_TAGS_TO_INVALIDATE,
    }),
  }),
});

// Auto generated hooks: https://redux-toolkit.js.org/rtk-query/api/created-api/hooks
export const {
  usePostUserMutation,
  usePostUserSmsOptInMutation,
  usePostUserSmsOptOutMutation,
  usePostQualifierMutation,
  useGetOnboardingStatusQuery,
  usePostLogPinwheelEventMutation,
  useGetCardApplicationStatusQuery,
  useGetCardApplicationTasksQuery,
  usePostCardApplicationConfirmRepaymentAccountMutation,
  useGetDisclosuresQuery,
  useGetActiveRecurringOutflowsQuery,
  useGetNotificationsQuery,
  usePostNotificationMarkAsReadMutation,
  useGetCardTransactionsQuery,
  useGetUserProfileQuery,
  useGetDashboardOverviewQuery,
  useGetDashboardPaydayQuery,
  useGetPinwheelStatusQuery,
  useGetPaystubsQuery,
  useGetEarningsQuery,
  usePostInternalMessageMutation,
  usePostPersonalInfosMutation,
  usePostIdentityVerificationSubmitVerificationMutation,
  usePostAcceptancesMutation,
  useGetIdentityVerificationStatusQuery,
  useGetBankAccountsLinkedQuery,
  useGetBankAccountVerificationStatusQuery,
  useGetStatementsQuery,
  useGetStatementQuery,
  usePostSendCodeMutation,
  usePostVerifyCodeMutation,
  useGetContentfulPageQuery,
  useGetContentfulPostQuery,
  useGetContentfulToggleBlockQuery,
  usePostSimulateTransactionMutation,
  usePostSimulateEarningsMutation,
  usePostSimulateDirectDepositMutation,
  usePostAdminUserStopImpersonatingMutation,
  usePostFinancialHealthSurveyMutation,
  usePostFinancialHealthBudgetMutation,

  // mock-only
  useGetMockUsersQuery,
  usePostMockInitMutation,
  usePostMockResetMutation,
  usePostMockCreateUserMutation,
  usePostMockSetUserMutation,
} = apiSlice;

export const getOnboardingStatus = (options?: {}) =>
  useGetOnboardingStatusQuery(undefined, options);

export const getCardApplicationStatus = (options?: {}) =>
  useGetCardApplicationStatusQuery(undefined, options);

export const getCardApplicationTasks = (options?: {}) =>
  useGetCardApplicationTasksQuery(undefined, options);

export const getDisclosures = (
  disclosureTypes?: ApiDisclosureType[],
  options?: {}
) => useGetDisclosuresQuery(disclosureTypes, options);

export const getActiveRecurringOutflows = (options?: {}) =>
  useGetActiveRecurringOutflowsQuery(undefined, options);

export const getNotifications = (options?: {}) =>
  useGetNotificationsQuery(undefined, options);

export const getCardTransactions = (options?: {}) =>
  useGetCardTransactionsQuery(undefined, options);

export const getUserProfile = (options?: {}) =>
  useGetUserProfileQuery(undefined, options);

export const getDashboardOverview = (options?: {}) =>
  useGetDashboardOverviewQuery(undefined, options);

export const getDashboardPayday = (options?: {}) =>
  useGetDashboardPaydayQuery(undefined, options);

export const getPinwheelStatus = (options?: {}) =>
  useGetPinwheelStatusQuery(undefined, options);

export const getPaystubs = (options?: {}) =>
  useGetPaystubsQuery(undefined, options);

export const getEarnings = (options?: {}) =>
  useGetEarningsQuery(undefined, options);

export const getIdentityVerificationStatus = (options?: {}) =>
  useGetIdentityVerificationStatusQuery(undefined, options);

export const getBankAccountsLinked = (options?: {}) =>
  useGetBankAccountsLinkedQuery(undefined, options);

export const getBankAccountVerificationStatus = (options?: {}) =>
  useGetBankAccountVerificationStatusQuery(undefined, options);

export const getStatements = (options?: {}) =>
  useGetStatementsQuery(undefined, options);

export const getStatement = (id: string, options?: {}) =>
  useGetStatementQuery(id, options);

export const getContentfulPage = ({ slug, section, preview }, options?: {}) =>
  useGetContentfulPageQuery({ slug, section, preview }, options);

export const getContentfulPost = ({ slug, preview }, options?: {}) =>
  useGetContentfulPostQuery({ slug, preview }, options);

export const getContentfulToggleBlock = ({ id }, options?: {}) =>
  useGetContentfulToggleBlockQuery({ id }, options);

// mock-only

export const getMockUsers = (options?: {}) =>
  useGetMockUsersQuery(undefined, options);

export default apiSlice;
