import React from "react";
import { Body } from "components/ui/Text";
import Button from "components/ui/Button";
import { LinkButton } from "components/ui/Link";
import InfoBox from "components/ui/InfoBox";
import { Eligibility } from "types/signup";
import * as Styled from "./styled";

interface Props {
  eligibility: { bank: Eligibility; payroll: Eligibility };
  resetForm: () => void;
  updateEmail: () => void;
  email: string;
}

export const getContent = ({
  email,
  eligibility,
  resetForm,
  updateEmail,
}: Props) => {
  const isEligible = Object.values(eligibility).every(
    (v) => v === Eligibility.eligible
  );
  const ineligibleList = Object.entries(eligibility).reduce(
    (acc, [key, value]) => {
      if (value === Eligibility.eligible) {
        return acc;
      }
      let name = key;
      switch (key) {
        case "payroll":
          name = "payroll provider";
          break;
        default:
      }
      return [...acc, name];
    },
    []
  );

  if (isEligible)
    return {
      eyebrow: "Welcome to Reset",
      heading: "We're excited to have you on board!",
      body: (
        <>
          <Body>
            We emailed a link to <strong>{email}</strong>. Please click it to
            verify your email address and continue. If you don't see the email
            in your inbox, please check your spam folder or wait a few minutes.
          </Body>

          <Styled.Ctas>
            <div>
              <LinkButton onClick={updateEmail} color="azure" size="lg">
                My email address is incorrect
              </LinkButton>
            </div>
          </Styled.Ctas>
        </>
      ),
    };
  return {
    eyebrow: "Thanks for your submission",
    heading: "We've added you to the waitlist!",
    body: (
      <>
        <Body>
          Reset is not currently compatible with your{" "}
          {ineligibleList.join(" or ")}, but we'll email you when that changes.
        </Body>

        {eligibility.payroll === Eligibility.ineligible && (
          <>
            <InfoBox>
              <Body color="violet">
                If you have a second source of income, you can try signing up
                again.
              </Body>
            </InfoBox>

            <Styled.Ctas>
              <Button onClick={resetForm}>Try Another Income Source</Button>

              <div>
                <LinkButton
                  onClick={() => window.location.reload()}
                  color="azure"
                >
                  Start Over
                </LinkButton>
              </div>
            </Styled.Ctas>
          </>
        )}
      </>
    ),
  };
};
