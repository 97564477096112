import React from "react";
import styled from "styled-components";
import { camelCaseToTitleCase } from "utils/string";
import { isDefined } from "utils/object";
import CriterionValue from "./CriterionValue";
import * as Styled from "../styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {
  criteria: { [name: string]: boolean | string | number };
}

const TYPE_ORDER = {
  boolean: 0,
  number: 1,
  string: 2,
};

const getTypeOrder = (value: any) => {
  const order = TYPE_ORDER[value];

  if (!isDefined(order)) return 3;

  return order;
};

const EligibilityCriteria: React.FC<Props> = ({ criteria }) => {
  const criteriaArray = Object.entries(criteria || {})
    .map(([name, value]) => ({ name, value }))
    .sort((a, b) => getTypeOrder(typeof a.value) - getTypeOrder(typeof b.value));

  return (
    <Wrapper>
      {criteriaArray.map((criterion) => (
        <Styled.InfoItem
          key={criterion.name}
          label={camelCaseToTitleCase(criterion.name.replace(/\?/g, ""))}
          value={<CriterionValue value={criterion.value} />}
        />
      ))}
    </Wrapper>
  );
};

export default EligibilityCriteria;
