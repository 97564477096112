import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
import UILabel from "components/ui/UILabel";
import { colors, mediaMax, transitions } from "utils/theme";

interface WrapperProps {
  $isDebit?: boolean;
  $isActive?: boolean;
}

export const IconWrapper = styled.div<WrapperProps>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 36px;
  height: 36px;

  background: ${colors.azure}20;
  border-radius: 9999px;

  ${(props) =>
    !props.$isActive &&
    css`
      filter: grayscale(1);
      opacity: 0.8;
    `}
`;

export const LineItemLink = styled(RouterLink)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: block;
  width: 100%;
  height: 100%;
`;

export const Main = styled.div`
  position: relative;

  min-width: 1px;
  flex: 1 1 auto;
  display: flex;
  gap: 12px;
  width: 100%;
  padding: 2px 0;
`;

export const Left = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Right = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: flex-end;
  gap: 0;
`;

export const Action = styled(motion.div)`
  flex: 0 0 auto;
`;

export const Title = styled(UILabel.Large).attrs(() => ({
  bold: true,
}))`
  display: flex;
  gap: 8px;
  margin: 0;

  transition: all ${transitions.simple};

  ${(props) => css`
    opacity: ${props.$isActive ? 1 : 0.7};
  `}

  & > * {
    flex: 0 1 auto;
  }

  ${mediaMax("md")} {
    font-size: 14px;
  }
`;

export const TitleIcon = styled.div`
  transform: translateX(-8px);

  display: none;
  flex: 0 0 auto;

  color: ${colors.violet};
  opacity: 0;
  visibility: hidden;

  transition: all ${transitions.simple};

  svg {
    stroke-width: 18px;
    stroke: currentColor;
  }

  @media (hover: hover) {
    display: block;
  }
`;

const getAmountColor = (props: WrapperProps) => {
  if (!props.$isActive) return `${colors.violet}80`;

  return props.$isDebit ? colors.violet : colors.azure;
};

export const Amount = styled(UILabel.XLarge).attrs((props: WrapperProps) => ({
  color: getAmountColor(props),
}))`
  margin: 0;
  flex: 0 0 auto;

  font-size: 24px;

  ${mediaMax("md")} {
    font-size: 20px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2px 8px;
  align-items: center;
`;

export const Subtitle = styled(UILabel.Micro).attrs(() => ({
  bold: true,
  caps: true,
}))`
  margin: 0;

  opacity: 0.7;
`;

export const Date = styled(UILabel.Small)`
  margin: 0;
  flex: 0 0 auto;

  opacity: 0.5;
`;

export const LineItem = styled.div<{ $isInteractive: boolean }>`
  position: relative;

  display: flex;
  min-width: 1px;
  gap: 12px;
  width: 100%;

  color: ${colors.midnight};

  text-decoration: none;

  ${(props) =>
    props.$isInteractive &&
    css`
      @media (hover: hover) {
        &:hover {
          ${Title} {
            color: ${colors.violet};
          }

          ${TitleIcon} {
            transform: translateX(0);

            opacity: 1;
            visibility: visible;
          }
        }
      }
    `}
`;

export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  padding: 8px 0 0 48px;
`;

export const ActionItem = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
`;

export const LineItemWrapper = styled.div`
  position: relative;
`;
