import { FormTaskStatus } from "types/task";
import { OnboardingTaskName } from "types/onboarding";

export const DEFAULT_TASKS = [
  {
    name: OnboardingTaskName.signup,
    state: FormTaskStatus.ready,
  },
  {
    name: OnboardingTaskName.bankLinking,
    state: FormTaskStatus.incomplete,
  },
  {
    name: OnboardingTaskName.payrollLinking,
    state: FormTaskStatus.incomplete,
  },
  {
    name: OnboardingTaskName.identityVerification,
    state: FormTaskStatus.incomplete,
  },
];

export const TASK_MAP: {
  [name in OnboardingTaskName]: {
    title: string;
  };
} = {
  [OnboardingTaskName.signup]: {
    title: "Sign up",
  },
  [OnboardingTaskName.bankLinking]: {
    title: "Link checking account",
  },
  [OnboardingTaskName.payrollLinking]: {
    title: "Verify income",
  },
  [OnboardingTaskName.termsAcceptance]: {
    title: "Review terms",
  },
  [OnboardingTaskName.identityVerification]: {
    title: "Submit application",
  },
  [OnboardingTaskName.depositSwitching]: {
    title: "Switch direct deposit",
  },
  [OnboardingTaskName.repaymentAccountAcknowledgement]: {
    title: "Confirm repayment account",
  },
};
