import React, { useRef, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Heading } from "components/ui/Text";
import Button, { ButtonLevel } from "components/ui/Button";
import PinwheelModal, {
  ModalApi as PinwheelModalApi,
} from "components/shared/PinwheelModal";
import Notification from "components/ui/Notification";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import IconList, { IconListItem } from "components/ui/IconList";
import { EventType, trackEvent } from "utils/analytics";
import * as Styled from "./styled";

const LIST_ITEMS: IconListItem[] = [
  {
    icon: "arrowProgress",
    title: "Why connect my payroll account?",
    body: (
      <>
        Reset uses payroll data to give you access to your income daily as you
        earn it. This has <strong>no impact</strong> on your direct deposit or
        the timing of your paycheck.
      </>
    ),
  },
  {
    icon: "stars",
    title: "Are there other benefits?",
    body: "Log in to Reset daily to keep track of your earnings and stay on top of your finances.",
  },
  {
    icon: "shield",
    title: "Is it safe?",
    body: "Your peace of mind is our priority. Reset uses Pinwheel to securely connect your payroll account and keep your data private.",
  },
];

const Payroll = () => {
  const flags = useFlags();
  const modalRef = useRef<PinwheelModalApi>(null);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => {
    if (modalRef && modalRef.current) {
      modalRef.current.open();
    }
  };

  const onSuccess = () => {
    trackEvent(EventType.userSignupSuccessful, {
      eventCallback: () => window.location.reload(),
    });
  };

  return (
    <Styled.FormStep>
      {!flags["rf-6-card-launch"] && (
        <Notification showIcon={false}>
          The Reset card is <u>coming soon</u>. App users will have early access
          to apply for the card when it is available.
        </Notification>
      )}

      <Styled.Header>
        <Heading.H2 tag="h1">
          Next let's connect your payroll account.
        </Heading.H2>
      </Styled.Header>

      <IconList items={LIST_ITEMS} isLarge />

      <MobileFixedFooter>
        <Button
          isLoading={isLoading}
          onClick={openModal}
          data-test="btn-link-payroll"
          level={ButtonLevel.cta}
        >
          Connect My Payroll Account
        </Button>
      </MobileFixedFooter>

      <Styled.ModalWrapper>
        <PinwheelModal
          ref={modalRef}
          onSubmit={() => {
            setIsLoading(true);
          }}
          onSuccess={onSuccess}
        />
      </Styled.ModalWrapper>
    </Styled.FormStep>
  );
};

export default Payroll;
