import React from "react";
import App from "App";
import Post from "components/cms/Post";
import { transformKeysDeep } from "utils/object";

const CmsPost = (props) => {
  const postData = transformKeysDeep(props, (key) => key.replace(/^__/, ""));
  const content = postData.post;

  return (
    <App>
      <Post content={content} />
    </App>
  );
};

export default CmsPost;
