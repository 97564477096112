import React from "react";
import sanitizeHtml from "sanitize-html";
import { INLINES } from "@contentful/rich-text-types";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { ApiContentfulPageMini } from "utils/api/contentful";
import { BlockBackground } from "types/cms";

const HEADING_SANITIZATION_OPTIONS = {
  allowedTags: ["b", "i", "em", "strong", "sub", "sup", "u", "s", "del"],
};

const getUrlFromEntry = (entry: ApiContentfulPageMini) => {
  const { slug, section } = entry;
  const path = [];
  if (section && section !== "none") {
    path.push(section);
  }
  path.push(slug);
  return path.join("/");
};

export const getDocumentToHtmlStringOptions = (pagesById) => ({
  renderNode: {
    [INLINES.HYPERLINK]: ({ data, content }, next) =>
      `<a href="${data.uri}" data-turbo="false">${next(content)}</a>`,
    [INLINES.ENTRY_HYPERLINK]: ({ data, content }, next) => {
      const entry = pagesById[data.target.sys.id];
      const path = getUrlFromEntry(entry);
      return `<a href="/${path}" data-turbo="false">${next(content)}</a>`;
    },
  },
});

export const Body = ({ data, pagesById }) =>
  data ? (
    <div
      dangerouslySetInnerHTML={{
        __html: documentToHtmlString(
          data,
          getDocumentToHtmlStringOptions(pagesById)
        ),
      }}
    />
  ) : null;

export const getBody = (body, pagesById = {}) =>
  body ? <Body data={body?.json} pagesById={pagesById} /> : null;

export const Heading = ({ html }) => (
  <div dangerouslySetInnerHTML={{ __html: html }} />
);

export const getHeading = (heading) => {
  const html = sanitizeHtml(heading, HEADING_SANITIZATION_OPTIONS);
  return heading ? <Heading html={html} /> : null;
};

export const buildCta = (cta) =>
  cta
    ? {
        type: cta.type,
        data: {
          text: cta.label,
          href: cta.url,
        },
      }
    : undefined;

const getBackground = (background) => {
  switch (background) {
    case "White":
      return BlockBackground.white;
    case "Light Blue":
      return BlockBackground.lightBlue;
    case "Gradient":
      return BlockBackground.gradient;
    case "Dark":
      return BlockBackground.dark;
    default:
      return null;
  }
};

export const mapImagePositionToType = (imagePosition) => {
  switch (imagePosition) {
    case "Left":
      return "left";
    case "Right":
      return "right";
    default:
      return imagePosition;
  }
};

export const mapImageAnimationToType = (imageAnimation) => {
  switch (imageAnimation) {
    case "Float":
      return "float";
    case "None":
    default:
      return null;
  }
};

export const mapTextSizeToType = (textSize) => {
  switch (textSize) {
    case "Large":
      return "large";
    case "Small":
      return "small";
    default:
      return textSize;
  }
};

export const mapTextAlignToType = (textAlign) => {
  switch (textAlign) {
    case "Left":
      return "left";
    case "Center":
    default:
      return "center";
  }
};

const mapDividerPositionToType = (dividerPosition) => {
  switch (dividerPosition) {
    case "Top":
      return "top";
    case "Top Mirrored":
      return "topMirrored";
    default:
      return null;
  }
};

export const getCommonBlockData = (block, pagesById) => ({
  eyebrow: block.eyebrow,
  heading: getHeading(block.heading),
  body: getBody(block.body, pagesById),
  background: getBackground(block.background),
  textSize: mapTextSizeToType(block.textSize),
  textAlign: mapTextAlignToType(block.textAlign),
  dividerPosition: mapDividerPositionToType(block.dividerPosition),
});
