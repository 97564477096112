import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title?: string;
  children?: React.ReactNode;
}

const DocumentHead: React.FC<Props> = ({ title, children }) => (
  <Helmet>
    {title && <title>{title} | Reset</title>}
    {children}
  </Helmet>
);

export default DocumentHead;
