import React, { useEffect } from "react";
import { Outlet, useLocation, useMatches } from "react-router-dom";
import AppLayout from "components/layout/AppLayout";
import DrawerLayout from "components/layout/DrawerLayout";
import { LayoutName } from "components/context/LayoutContext";
import DocumentHead from "components/ui/DocumentHead";
import Authenticator from "./Authenticator";

interface RouteMetadata {
  title?: string;
}

const Root: React.FC = () => {
  const matches = useMatches();
  const location = useLocation();

  const { pathname } = location;
  const match = matches[matches.length - 1];
  const { title } = (match?.handle || {}) as RouteMetadata;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <DrawerLayout>
      {({ openWith, close }) => (
        <AppLayout layoutName={LayoutName.withSidebar}>
          <DocumentHead title={title} />
          
          <Authenticator>
            <Outlet context={{ drawer: { openWith, close } }} />
          </Authenticator>
        </AppLayout>
      )}
    </DrawerLayout>
  );
};

export default Root;
