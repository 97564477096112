import Button from "components/ui/Button";
import styled, { css } from "styled-components";
import {
  borderRadius,
  colors,
  mediaMax,
  mediaMin,
  shadows,
  zIndex,
} from "utils/theme";
import { PositionX, PositionY } from "./types";

export const ChatWrapper = styled.div``;

export const ButtonLabel = styled.div``;

export const ChatButton = styled(Button)`
  ${mediaMax("lg")} {
    padding: 0;
    width: 44px;
    height: 44px;

    ${ButtonLabel} {
      display: none;
    }
  }

  ${mediaMin("lg")} {
    svg {
      margin-left: -0.2em;
    }
  }
`;

export const Wrapper = styled.div<{
  $isOpen: boolean;
  $isReady: boolean;
  $positionX: PositionX;
  $positionY: PositionY;
  $positionMobileX: PositionX;
  $positionMobileY: PositionY;
}>`
  ${(props) =>
    !props.$isReady &&
    css`
      position: absolute;

      opacity: 0;
      visibility: hidden;
    `}

  ${ChatWrapper} {
    position: absolute;

    width: 360px;

    iframe {
      position: relative;

      width: 360px;
      max-width: 100%;
      height: 540px;
      max-height: 100vh;

      border: 0;
      border-radius: ${borderRadius.large}px;
      box-shadow:
        ${shadows.tile},
        0 4px 24px ${colors.violet}22;
    }

    ${(props) => props.$positionY}: calc(100% + 16px);
    ${(props) => props.$positionX}: 0;

    ${mediaMax("lg")} {
      ${(props) =>
        props.$positionMobileY &&
        css`
          ${props.$positionMobileY}: calc(100% + 16px);
        `}
      ${(props) =>
        props.$positionMobileX &&
        css`
          ${props.$positionMobileX}: 0;
        `}
    }
  }

  ${mediaMax("md")} {
    ${(props) =>
      props.$isOpen &&
      css`
        position: fixed;
        top: 0;
        left: 0;
        z-index: ${zIndex.modal};

        width: 100vw;
        height: 100vh;

        &::before {
          content: "";

          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;

          display: block;
          width: 100%;
          height: 100%;

          background: ${colors.midnight}80;
        }

        ${ChatButton} {
          position: absolute;
          top: 34px;
          right: 12px;
          transform: translateY(-50%);
          z-index: 1;

          color: ${colors.violet};
          background: ${colors.flax};
        }

        ${ChatWrapper} {
          position: absolute;
          bottom: 80px;
          top: 0;
          bottom: 0;
          left: 0;

          width: 100%;

          iframe {
            width: 100%;
            height: 100vh;

            border-radius: 0;
          }
        }
      `}
  }
`;
