import { faImageSlash } from "@fortawesome/pro-light-svg-icons/faImageSlash";
import { faPiggyBank } from "@fortawesome/pro-light-svg-icons/faPiggyBank";
import { faFileInvoiceDollar } from "@fortawesome/pro-light-svg-icons/faFileInvoiceDollar";
import { faDollarSign } from "@fortawesome/pro-light-svg-icons/faDollarSign";
import { faGrid2 } from "@fortawesome/pro-light-svg-icons/faGrid2";
import { faCreditCard } from "@fortawesome/pro-light-svg-icons/faCreditCard";
import { faDollarCircle } from "@fortawesome/pro-light-svg-icons/faDollarCircle";
import { faUserCircle } from "@fortawesome/pro-light-svg-icons/faUserCircle";
import { faShoppingBag } from "@fortawesome/pro-light-svg-icons/faShoppingBag";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle";
import { faXmark } from "@fortawesome/pro-light-svg-icons/faXmark";
import { faMoneyBillTransfer } from "@fortawesome/pro-light-svg-icons/faMoneyBillTransfer";
import { faHandHoldingDollar } from "@fortawesome/pro-light-svg-icons/faHandHoldingDollar";
import { faBuildingColumns } from "@fortawesome/pro-light-svg-icons/faBuildingColumns";
import { faPopcorn } from "@fortawesome/pro-light-svg-icons/faPopcorn";
import { faForkKnife } from "@fortawesome/pro-light-svg-icons/faForkKnife";
import { faScrewdriverWrench } from "@fortawesome/pro-light-svg-icons/faScrewdriverWrench";
import { faUserDoctor } from "@fortawesome/pro-light-svg-icons/faUserDoctor";
import { faSpa } from "@fortawesome/pro-light-svg-icons/faSpa";
import { faRectanglesMixed } from "@fortawesome/pro-light-svg-icons/faRectanglesMixed";
import { faLandmark } from "@fortawesome/pro-light-svg-icons/faLandmark";
import { faCar } from "@fortawesome/pro-light-svg-icons/faCar";
import { faUtilityPole } from "@fortawesome/pro-light-svg-icons/faUtilityPole";
import { faFaucetDrip } from "@fortawesome/pro-light-svg-icons/faFaucetDrip";
import { faMobileNotch } from "@fortawesome/pro-light-svg-icons/faMobileNotch";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons/faTrashCan";
import { faBuilding } from "@fortawesome/pro-light-svg-icons/faBuilding";
import { faBoltLightning } from "@fortawesome/pro-light-svg-icons/faBoltLightning";
import { faWifi } from "@fortawesome/pro-light-svg-icons/faWifi";
import { faCarSideBolt } from "@fortawesome/pro-light-svg-icons/faCarSideBolt";
import { faBed } from "@fortawesome/pro-light-svg-icons/faBed";
import { faPersonWalkingLuggage } from "@fortawesome/pro-light-svg-icons/faPersonWalkingLuggage";
import { faPlaneDeparture } from "@fortawesome/pro-light-svg-icons/faPlaneDeparture";
import { faHouseChimney } from "@fortawesome/pro-light-svg-icons/faHouseChimney";
import { faGraduationCap } from "@fortawesome/pro-light-svg-icons/faGraduationCap";
import { faMagnifyingGlassDollar } from "@fortawesome/pro-light-svg-icons/faMagnifyingGlassDollar";
import { faMoneyFromBracket } from "@fortawesome/pro-light-svg-icons/faMoneyFromBracket";
import { faGlobe } from "@fortawesome/pro-light-svg-icons/faGlobe";
import { faChartLineDown } from "@fortawesome/pro-light-svg-icons/faChartLineDown";
import { faPercent } from "@fortawesome/pro-light-svg-icons/faPercent";
import { faCards } from "@fortawesome/pro-light-svg-icons/faCards";
import { faMusic } from "@fortawesome/pro-light-svg-icons/faMusic";
import { faRollerCoaster } from "@fortawesome/pro-light-svg-icons/faRollerCoaster";
import { faGamepadModern } from "@fortawesome/pro-light-svg-icons/faGamepadModern";
import { faHandshakeAngle } from "@fortawesome/pro-light-svg-icons/faHandshakeAngle";
import { faPartyHorn } from "@fortawesome/pro-light-svg-icons/faPartyHorn";
import { faWineBottle } from "@fortawesome/pro-light-svg-icons/faWineBottle";
import { faMugHot } from "@fortawesome/pro-light-svg-icons/faMugHot";
import { faBurgerFries } from "@fortawesome/pro-light-svg-icons/faBurgerFries";
import { faCartShopping } from "@fortawesome/pro-light-svg-icons/faCartShopping";
import { faUserChef } from "@fortawesome/pro-light-svg-icons/faUserChef";
import { faCandyBar } from "@fortawesome/pro-light-svg-icons/faCandyBar";
import { faBook } from "@fortawesome/pro-light-svg-icons/faBook";
import { faShirt } from "@fortawesome/pro-light-svg-icons/faShirt";
import { faCashRegister } from "@fortawesome/pro-light-svg-icons/faCashRegister";
import { faBoxTaped } from "@fortawesome/pro-light-svg-icons/faBoxTaped";
import { faTags } from "@fortawesome/pro-light-svg-icons/faTags";
import { faTelevision } from "@fortawesome/pro-light-svg-icons/faTelevision";
import { faGift } from "@fortawesome/pro-light-svg-icons/faGift";
import { faStapler } from "@fortawesome/pro-light-svg-icons/faStapler";
import { faStore } from "@fortawesome/pro-light-svg-icons/faStore";
import { faDogLeashed } from "@fortawesome/pro-light-svg-icons/faDogLeashed";
import { faBaseballBatBall } from "@fortawesome/pro-light-svg-icons/faBaseballBatBall";
import { faSmoking } from "@fortawesome/pro-light-svg-icons/faSmoking";
import { faPalette } from "@fortawesome/pro-light-svg-icons/faPalette";
import { faCouch } from "@fortawesome/pro-light-svg-icons/faCouch";
import { faSwimmingPool } from "@fortawesome/pro-light-svg-icons/faSwimmingPool";
import { faLightbulb } from "@fortawesome/pro-light-svg-icons/faLightbulb";
import { faCameraCctv } from "@fortawesome/pro-light-svg-icons/faCameraCctv";
import { faTooth } from "@fortawesome/pro-light-svg-icons/faTooth";
import { faGlassesRound } from "@fortawesome/pro-light-svg-icons/faGlassesRound";
import { faUserNurse } from "@fortawesome/pro-light-svg-icons/faUserNurse";
import { faPrescriptionBottle } from "@fortawesome/pro-light-svg-icons/faPrescriptionBottle";
import { faPawSimple } from "@fortawesome/pro-light-svg-icons/faPawSimple";
import { faAmbulance } from "@fortawesome/pro-light-svg-icons/faAmbulance";
import { faDumbbell } from "@fortawesome/pro-light-svg-icons/faDumbbell";
import { faWashingMachine } from "@fortawesome/pro-light-svg-icons/faWashingMachine";
import { faScissors } from "@fortawesome/pro-light-svg-icons/faScissors";
import { faChartMixedUpCircleDollar } from "@fortawesome/pro-light-svg-icons/faChartMixedUpCircleDollar";
import { faCarWash } from "@fortawesome/pro-light-svg-icons/faCarWash";
import { faBaby } from "@fortawesome/pro-light-svg-icons/faBaby";
import { faScaleBalanced } from "@fortawesome/pro-light-svg-icons/faScaleBalanced";
import { faCarBurst } from "@fortawesome/pro-light-svg-icons/faCarBurst";
import { faMailboxFlagUp } from "@fortawesome/pro-light-svg-icons/faMailboxFlagUp";
import { faBoxes } from "@fortawesome/pro-light-svg-icons/faBoxes";
import { faChalkboardUser } from "@fortawesome/pro-light-svg-icons/faChalkboardUser";
import { faHandHoldingHeart } from "@fortawesome/pro-light-svg-icons/faHandHoldingHeart";
import { faSackDollar } from "@fortawesome/pro-light-svg-icons/faSackDollar";
import { faBicycle } from "@fortawesome/pro-light-svg-icons/faBicycle";
import { faGasPump } from "@fortawesome/pro-light-svg-icons/faGasPump";
import { faParking } from "@fortawesome/pro-light-svg-icons/faParking";
import { faTrain } from "@fortawesome/pro-light-svg-icons/faTrain";
import { faTaxi } from "@fortawesome/pro-light-svg-icons/faTaxi";
import { faIdCard } from "@fortawesome/pro-light-svg-icons/faIdCard";
import { faBridgeSuspension } from "@fortawesome/pro-light-svg-icons/faBridgeSuspension";
import { faCarBus } from "@fortawesome/pro-light-svg-icons/faCarBus";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons/faTriangleExclamation";
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faArrowLeftLong } from "@fortawesome/pro-light-svg-icons/faArrowLeftLong";
import { faDoorOpen } from "@fortawesome/pro-light-svg-icons/faDoorOpen";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons/faPlusCircle";
import { faMinusCircle } from "@fortawesome/pro-light-svg-icons/faMinusCircle";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-light-svg-icons/faMinus";
import { faCalendarDay } from "@fortawesome/pro-light-svg-icons/faCalendarDay";
import { faArrowRightLong } from "@fortawesome/pro-light-svg-icons/faArrowRightLong";
import { faArrowDownLong } from "@fortawesome/pro-light-svg-icons/faArrowDownLong";
import { faArrowUpLong } from "@fortawesome/pro-light-svg-icons/faArrowUpLong";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown";
import { faChevronUp } from "@fortawesome/pro-light-svg-icons/faChevronUp";
import { faShieldCheck } from "@fortawesome/pro-light-svg-icons/faShieldCheck";
import { faCommentSms } from "@fortawesome/pro-light-svg-icons/faCommentSms";
import { faBriefcase } from "@fortawesome/pro-light-svg-icons/faBriefcase";
import { faLockKeyhole } from "@fortawesome/pro-light-svg-icons/faLockKeyhole";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle";
import { faEye } from "@fortawesome/pro-light-svg-icons/faEye";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons/faSpinnerThird";
import { faMoneyCheck } from "@fortawesome/pro-light-svg-icons/faMoneyCheck";
import { faComment } from "@fortawesome/pro-light-svg-icons/faComment";
import { faFaceParty } from "@fortawesome/pro-light-svg-icons/faFaceParty";
import { faCancel } from "@fortawesome/pro-light-svg-icons/faCancel";
import { faAt } from "@fortawesome/pro-light-svg-icons/faAt";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons/faCheckCircle";
import { faFiles } from "@fortawesome/pro-light-svg-icons/faFiles";
import { faFileLines } from "@fortawesome/pro-light-svg-icons/faFileLines";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare";
import { faArrowDownToLine } from "@fortawesome/pro-light-svg-icons/faArrowDownToLine";
import { faSquareArrowUpRight } from "@fortawesome/pro-light-svg-icons/faSquareArrowUpRight";
import { faCommentSmile } from "@fortawesome/pro-light-svg-icons/faCommentSmile";
import { faCommentQuestion } from "@fortawesome/pro-light-svg-icons/faCommentQuestion";
import { faCommentsQuestion } from "@fortawesome/pro-light-svg-icons/faCommentsQuestion";
import { faHandWave } from "@fortawesome/pro-light-svg-icons/faHandWave";
import { faToggleOn } from "@fortawesome/pro-light-svg-icons/faToggleOn";
import { faPowerOff } from "@fortawesome/pro-light-svg-icons/faPowerOff";
import { faBars } from "@fortawesome/pro-light-svg-icons/faBars";
import { faSquareList } from "@fortawesome/pro-light-svg-icons/faSquareList";
import { faRefresh } from "@fortawesome/pro-light-svg-icons/faRefresh";
import { faClipboard } from "@fortawesome/pro-light-svg-icons/faClipboard";
import { faUserSecret } from "@fortawesome/pro-light-svg-icons/faUserSecret";
import { faEllipsis } from "@fortawesome/pro-light-svg-icons/faEllipsis";
import { faEllipsisVertical } from "@fortawesome/pro-light-svg-icons/faEllipsisVertical";
import { faLightbulbOn } from "@fortawesome/pro-light-svg-icons/faLightbulbOn";
import { faClipboardListCheck } from "@fortawesome/pro-light-svg-icons/faClipboardListCheck";
import { faMoneyBillWave } from "@fortawesome/pro-light-svg-icons/faMoneyBillWave";
import { faEnvelopeOpenDollar } from "@fortawesome/pro-light-svg-icons/faEnvelopeOpenDollar";
import { faCalculator } from "@fortawesome/pro-light-svg-icons/faCalculator";
import { faShuffle } from "@fortawesome/pro-light-svg-icons/faShuffle";
import { faWallet } from "@fortawesome/pro-light-svg-icons/faWallet";
import { faStethoscope } from "@fortawesome/pro-light-svg-icons/faStethoscope";
import { faCubes } from "@fortawesome/pro-light-svg-icons/faCubes";
import { faTeddyBear } from "@fortawesome/pro-light-svg-icons/faTeddyBear";
import { faCameraMovie } from "@fortawesome/pro-light-svg-icons/faCameraMovie";
import { faStars } from "@fortawesome/pro-light-svg-icons/faStars";
import { faFaceLaughBeam } from "@fortawesome/pro-light-svg-icons/faFaceLaughBeam";
import { faBadgeDollar } from "@fortawesome/pro-light-svg-icons/faBadgeDollar";
import { faUserAstronaut } from "@fortawesome/pro-light-svg-icons/faUserAstronaut";
import { faRocketLaunch } from "@fortawesome/pro-light-svg-icons/faRocketLaunch";
import { faDice } from "@fortawesome/pro-light-svg-icons/faDice";
import { faClock } from "@fortawesome/pro-light-svg-icons/faClock";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons/faMagnifyingGlass";
import { faUsersRectangle } from "@fortawesome/pro-light-svg-icons/faUsersRectangle";
import { faArrowProgress } from "@fortawesome/pro-light-svg-icons/faArrowProgress";
import { faBallotCheck } from "@fortawesome/pro-light-svg-icons/faBallotCheck";
import { faThumbsUp } from "@fortawesome/pro-light-svg-icons/faThumbsUp";

import { faGrid2 as faGrid2Solid } from "@fortawesome/pro-solid-svg-icons/faGrid2";
import { faCreditCard as faCreditCardSolid } from "@fortawesome/pro-solid-svg-icons/faCreditCard";
import { faDollarCircle as faDollarCircleSolid } from "@fortawesome/pro-solid-svg-icons/faDollarCircle";
import { faUserCircle as faUserCircleSolid } from "@fortawesome/pro-solid-svg-icons/faUserCircle";
import { faFileInvoiceDollar as faFileInvoiceDollarSolid } from "@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar";
import { faCaretRight as faCaretRightSolid } from "@fortawesome/pro-solid-svg-icons/faCaretRight";

import { faCircleEllipsis as faCircleEllipsisDuo } from "@fortawesome/pro-duotone-svg-icons/faCircleEllipsis";
import { faTriangleExclamation as faTriangleExclamationDuo } from "@fortawesome/pro-duotone-svg-icons/faTriangleExclamation";
import { faCircleCheck as faCircleCheckDuo } from "@fortawesome/pro-duotone-svg-icons/faCircleCheck";
import { faCircleX as faCircleXDuo } from "@fortawesome/pro-duotone-svg-icons/faCircleX";

import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";

export const ICON = {
  // dashboard
  piggyBank: faPiggyBank,
  dollar: faDollarSign,
  invoice: faFileInvoiceDollar,
  invoiceFill: faFileInvoiceDollarSolid,

  // nav
  dashboard: faGrid2,
  dashboardFill: faGrid2Solid,
  transactions: faCreditCard,
  transactionsFill: faCreditCardSolid,
  income: faDollarCircle,
  incomeFill: faDollarCircleSolid,
  account: faUserCircle,
  accountFill: faUserCircleSolid,
  menu: faBars,

  // general
  imageSlash: faImageSlash,
  shoppingBag: faShoppingBag,
  questionCircle: faQuestionCircle,
  close: faXmark,
  caretRightSolid: faCaretRightSolid,
  warningTriangle: faTriangleExclamation,
  userSecret: faUserSecret,
  checkmark: faCheck,
  doorOpen: faDoorOpen,
  userCircle: faUserCircle,
  plusCircle: faPlusCircle,
  minusCircle: faMinusCircle,
  plus: faPlus,
  minus: faMinus,
  calendar: faCalendarDay,
  lock: faLockKeyhole,
  infoCircle: faInfoCircle,
  eye: faEye,
  spinner: faSpinnerThird,
  paycheck: faMoneyCheck,
  chat: faComment,
  faceParty: faFaceParty,
  cancel: faCancel,
  email: faAt,
  checkCircle: faCheckCircle,
  files: faFiles,
  document: faFileLines,
  externalLink: faArrowUpRightFromSquare,
  download: faArrowDownToLine,
  openWindow: faSquareArrowUpRight,
  commentSmile: faCommentSmile,
  commentQuestion: faCommentQuestion,
  commentsQuestion: faCommentsQuestion,
  handWave: faHandWave,
  toggleOn: faToggleOn,
  powerOff: faPowerOff,
  chartLineDown: faChartLineDown,
  linkedin: faLinkedin,
  squareList: faSquareList,
  refresh: faRefresh,
  clipboard: faClipboard,
  ellipsis: faEllipsis,
  ellipsisVertical: faEllipsisVertical,
  lightbulbOn: faLightbulbOn,
  clipboardListCheck: faClipboardListCheck,
  moneyBillWave: faMoneyBillWave,
  envelopeOpenDollar: faEnvelopeOpenDollar,
  calculator: faCalculator,
  shuffle: faShuffle,
  wallet: faWallet,
  stethoscope: faStethoscope,
  cubes: faCubes,
  teddyBear: faTeddyBear,
  movieCamera: faCameraMovie,
  stars: faStars,
  faceLaughBeam: faFaceLaughBeam,
  dollarBadge: faBadgeDollar,
  astronaut: faUserAstronaut,
  rocketLaunch: faRocketLaunch,
  dice: faDice,
  clock: faClock,
  search: faMagnifyingGlass,
  usersRectangle: faUsersRectangle,
  arrowProgress: faArrowProgress,
  ballotCheck: faBallotCheck,
  thumbsUp: faThumbsUp,

  // status
  statusPending: faCircleEllipsisDuo,
  statusWarning: faTriangleExclamationDuo,
  statusSuccess: faCircleCheckDuo,
  statusDanger: faCircleXDuo,

  // home
  shield: faShieldCheck,
  commentSms: faCommentSms,
  briefcase: faBriefcase,

  // arrows
  arrowLeft: faArrowLeftLong,
  arrowRight: faArrowRightLong,
  arrowDown: faArrowDownLong,
  arrowUp: faArrowUpLong,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  chevronDown: faChevronDown,
  chevronUp: faChevronUp,

  // transaction categories
  transfer: faMoneyBillTransfer,
  loan: faHandHoldingDollar,
  bank: faBuildingColumns,
  popcorn: faPopcorn,
  food: faForkKnife,
  tools: faScrewdriverWrench,
  medical: faUserDoctor,
  spa: faSpa,
  services: faRectanglesMixed,
  landmark: faLandmark,
  car: faCar,
  plane: faPlaneDeparture,
  utilities: faUtilityPole,
  faucet: faFaucetDrip,
  phone: faMobileNotch,
  trash: faTrashCan,
  building: faBuilding,
  wifi: faWifi,
  electric: faBoltLightning,
  rental: faCarSideBolt,
  bed: faBed,
  luggage: faPersonWalkingLuggage,
  creditCard: faCreditCard,
  house: faHouseChimney,
  student: faGraduationCap,
  fee: faMagnifyingGlassDollar,
  atm: faMoneyFromBracket,
  globe: faGlobe,
  chartDown: faChartLineDown,
  percentage: faPercent,
  playingCards: faCards,
  music: faMusic,
  rollerCoaster: faRollerCoaster,
  videoGame: faGamepadModern,
  handshake: faHandshakeAngle,
  party: faPartyHorn,
  wine: faWineBottle,
  coffee: faMugHot,
  hamburger: faBurgerFries,
  grocery: faCartShopping,
  restaurant: faUserChef,
  candyBar: faCandyBar,
  book: faBook,
  shirt: faShirt,
  cashRegister: faCashRegister,
  store: faStore,
  discount: faTags,
  gadget: faTelevision,
  gift: faGift,
  stapler: faStapler,
  box: faBoxTaped,
  dogLeashed: faDogLeashed,
  baseball: faBaseballBatBall,
  cigarette: faSmoking,
  palette: faPalette,
  couch: faCouch,
  lightbulb: faLightbulb,
  security: faCameraCctv,
  pool: faSwimmingPool,
  tooth: faTooth,
  glasses: faGlassesRound,
  nurse: faUserNurse,
  pharmacy: faPrescriptionBottle,
  doctor: faUserDoctor,
  veteranarian: faPawSimple,
  ambulance: faAmbulance,
  dumbbell: faDumbbell,
  washer: faWashingMachine,
  scissors: faScissors,
  dollarChart: faChartMixedUpCircleDollar,
  carWash: faCarWash,
  baby: faBaby,
  scale: faScaleBalanced,
  chalkboard: faChalkboardUser,
  accident: faCarBurst,
  mailbox: faMailboxFlagUp,
  storage: faBoxes,
  charity: faHandHoldingHeart,
  idCard: faIdCard,
  moneyBag: faSackDollar,
  bicycle: faBicycle,
  gasoline: faGasPump,
  parking: faParking,
  bridge: faBridgeSuspension,
  carBus: faCarBus,
  train: faTrain,
  taxi: faTaxi,
};

export const ICON_SIZE = {
  xs: 12,
  sm: 18,
  md: 24,
  lg: 30,
  xl: 40,
};
